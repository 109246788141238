import { Partners } from "../components/partners";
import { ProductsSection } from "../components/contactinvestors";

function Products() {
  return (
    <>
      <ProductsSection />
      <Partners />
    </>
  );
}

export default Products;