const categories = [
  {
    name: 'Informacje finansowe',
    pageID: '/inwestorzy/informacje-finansowe',
  }, {
    name: 'Raporty',
    pageID: '/inwestorzy/raporty',
  }, {
    name: 'Władze spółki',
    pageID: '/inwestorzy/wladze-spolki',
  }, {
    name: 'Giełda',
    pageID: '/inwestorzy/gielda',
  }, {
    name: 'Kontakt dla inwestorów',
    pageID: '/inwestorzy/kontakt-dla-inwestorow',
  }
]

const productsHeading = 'Dla inwestorów';
const catHeading = 'Dla inwestorów ';


export { categories, productsHeading, catHeading };
