import { Partners } from "../components/partners";
import { ProductsSection } from "../components/infofinvestors";

function Products() {
    return (
        <>
            <ProductsSection />
            <Partners />
        </>
    );
}

export default Products;