import { Component } from 'react';
import { categories, catHeading } from './cms-data/investors-data';
import { Link } from 'react-router-dom';
import * as React from 'react';

export class ProductsSection extends Component {
  constructor(props) {
    super(props);
    this.handler = this.handler.bind(this)
    this.state = {
      cat: 'Dla inwestorów',
      currentCat: localStorage.currentCat
    };
  }
  handler(event) {
    this.setState({
      cat: event.target.innerHTML
    }, () => {
      localStorage.setItem("currentCat", this.state.cat);
    })
  }

  render() {

    return (
      <section className="products">
        <div className="container">
          <Categories handler={this.handler} passCat={this.state.cat} />
          <div className="product-list">
            <h2>Kontakt dla inwestorów</h2>
            <div className="inv-h3">
              <h3>Osoby odpowiedzialne za komunikację z inwestorami:{'\n'}</h3>
              <div className="product-list"><h4>&nbsp;</h4></div>
              <div className="inv-contacts">
                <h4>Grzegorz Henryk</h4>
                <h5><a href={`mailto:inwestycje@rcmedical.pl`}>inwestycje@rcmedical.pl</a></h5>
                <a href={`tel:+48 602 353 671`}>+48 602 353 671</a>
                <div className="product-list"><h3>&nbsp;</h3></div>
              </div>
              <h3>Dane rejestrowe:</h3>
              <div className="inv-contacts">
                <h4>RC MEDICAL S.A.</h4>
                <h5>ul. Mehoffera 10</h5>
                <h5>31-322 Kraków</h5>
                <h5>NIP: 6762503470</h5>
                <h5>REGON: 363946279</h5>
              </div>
              <h6 className="inv-wpis">wpisana do rejestru przedsiębiorców Krajowego Rejestru Sądowego pod numerem KRS 0000607445
                Sąd Rejonowy dla Krakowa-Śródmieścia w Krakowie, XI Wydział Gospodarczy Krajowego Rejestru Sądowego{'\n'}</h6>


            </div>
          </div>
        </div>
      </section>

    )
  }

}

class Categories extends Component {
  render() {
    return (
      <div className="categories" data-aos='fade-right'>
        <div className="cat-sticky">
          <span className="cat-heading">{catHeading}</span>
          <CategoryList click={this.props.handler} />
        </div>
      </div>
    )
  }
}

class CategoryList extends Component {

  render() {
    return (
      categories.map((element, index) =>
        <Link to={element.pageID} onClick={this.props.click} key={`cat-${index}`} className="cat">{element.name}</Link>
      )
    )
  }
}
