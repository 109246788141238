import { Component } from 'react';
import { categories, catHeading } from './cms-data/investors-data';
import { Link } from 'react-router-dom';
import * as React from 'react';

export class ProductsSection extends Component {
    constructor(props) {
        super(props);
        this.handler = this.handler.bind(this)
        this.state = {
            cat: 'Dla inwestorów',
            currentCat: localStorage.currentCat
        };
    }
    handler(event) {
        this.setState({
            cat: event.target.innerHTML
        }, () => {
            localStorage.setItem("currentCat", this.state.cat);
        })
    }

    render() {

        return (
            <section className="products">
                <div className="container">
                    <Categories handler={this.handler} passCat={this.state.cat} />
                    <div className="product-list">
                        <h2>Informacje finansowe</h2>
                        <div className="inv-h3">
                            <h5>&nbsp;</h5>
                            <h3>2021 rok:{'\n'}</h3>
                            <div className="product-list"></div>
                            <div className="inv-contacts">
                                <h5>Przychód:</h5>
                                <h4>49 438 406,00 PLN</h4>
                                <h6>&nbsp;</h6>
                                <h5>Zysk Netto:</h5>
                                <h4>4 157 384,48 PL</h4>
                                <h6>&nbsp;</h6>
                                <h5>Suma bilansowa:</h5>
                                <h4>33 092 090,44 PLN</h4>
                                <h3>&nbsp;</h3>
                            </div>
                            <h3>2020 rok:{'\n'}</h3>
                            <div className="product-list"></div>
                            <div className="inv-contacts">
                                <h5>Przychód:</h5>
                                <h4>48 131 100,57 PLN</h4>
                                <h6>&nbsp;</h6>
                                <h5>Zysk Netto:</h5>
                                <h4>2 578 826,76 PLN</h4>
                                <h6>&nbsp;</h6>
                                <h5>Suma bilansowa:</h5>
                                <h4>14 069 557,27 PLN</h4>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

        )
    }

}

class Categories extends Component {
    render() {
        return (
            <div className="categories" data-aos='fade-right'>
                <div className="cat-sticky">
                    <span className="cat-heading">{catHeading}</span>
                    <CategoryList click={this.props.handler} />
                </div>
            </div>
        )
    }
}

class CategoryList extends Component {

    render() {
        return (
            categories.map((element, index) =>
                <Link to={element.pageID} onClick={this.props.click} key={`cat-${index}`} className="cat">{element.name}</Link>
            )
        )
    }
}
